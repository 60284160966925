import React from 'react';
const AboutUs = () => {
  return (
    <section id="about" className="section">
      <div className="container">
        {/* Heading */}
        <p className="text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">Over Mij</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          Leer Me Kennen
        </h2>
        {/* Heading end*/}
        <div className="row">
          <div className="col-lg-8 text-center text-lg-start wow fadeInUp">
            <h2 className="text-8 fw-400 mb-3">
              Hallo, Ik ben{'  '}
              <span className="fw-700 border-bottom border-3 border-primary">
                Andr&eacute; de Groot
              </span>
            </h2>
            <p className="text-5">
              Ik ben een gemotiveerd en veelzijdig persoon, die altijd graag
              nieuwe uitdagingen aangaat. Met een passie voor leren ben ik
              toegewijd aan het leveren van kwalitatief hoogwaardige resultaten.
              Met een positieve instelling en een groeimindset ben ik klaar om
              een betekenisvolle bijdrage te leveren en geweldige dingen te
              bereiken.
            </p>
          </div>
          <div
            className="col-lg-4 mt-4 mt-lg-0 wow fadeInUp"
            data-wow-delay="0.2s"
          >
            <div className="featured-box style-4">
              <div className="featured-box-icon text-25 fw-500 bg-primary rounded-circle">
                <span className="wow heartBeat" data-wow-delay="1.3s">
                  6
                </span>
              </div>
              <h3 className="text-7 wow rubberBand" data-wow-delay="2s">
                Jaren <span className="fw-700">Ervaring</span>
              </h3>
            </div>
          </div>
        </div>
        <div className="row gy-3 mt-4">
          <div className="col-6 col-lg-3 wow fadeInUp">
            <p className="text-muted fw-500 mb-0">Naam:</p>
            <p className="text-4 text-dark fw-600 mb-0">
              Andr&eacute; de Groot
            </p>
          </div>
          {/* <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.2s">
            <p className="text-muted fw-500 mb-0">Email:</p>
            <p className="text-4 fw-600 mb-0">
              <a className="link-dark" href="mailto:info@amdegroot.dev">
                info@amdegroot.dev
              </a>
            </p>
          </div> */}
          {/*<div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.3s">
            <p className="text-muted fw-500 mb-0">Geboortedatum:</p>
            <p className="text-4 text-dark fw-600 mb-0">28 Juli 1974</p>
          </div>*/}
          <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.4s">
            <p className="text-muted fw-500 mb-0">Van:</p>
            <p className="text-4 text-dark fw-600 mb-0">Amsterdam, NL</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
